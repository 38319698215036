<template>
  <el-card shadow="never">
    <div slot="header" class="clearfix">
      <div>选择客户：
        <el-select v-model="customerId" @change="showLineCharts()" placeholder="请选择">
          <el-option
              v-for="item in customerOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <span style="margin-left: 20px">
          <el-button size="medium" :type="activeName===7?'info':''" @click="handleClick(7)">近7日</el-button>
          <el-button size="medium" :type="activeName===15?'info':''" @click="handleClick(15)">近15日</el-button>
          <el-button size="medium" :type="activeName===30?'info':''" @click="handleClick(30)">近30日</el-button>
        </span>
        <span style="margin-left: 20px">
          <el-date-picker
              v-model="month"
              @change="showLineCharts()"
              type="month"
              placeholder="选择月">
        </el-date-picker>
       </span>
      </div>
    </div>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card shadow="hover">
          <div slot="header" class="clearfix" style="height:60px;font-size:25px;">
            {{ this.showCount }}
          </div>
          <div class="text item" style="font-size:10px;">
            {{ this.showtext1 }}
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="hover">
          <div slot="header" class="clearfix" style="height:60px;font-size:25px;">
            {{ this.clickCount }}
          </div>
          <div class="text item" style="font-size:10px">
            {{ this.showtext2 }}
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="hover">
          <div slot="header" class="clearfix" style="height:60px;font-size:25px;">
            {{ this.totalShowCount }}
          </div>
          <div class="text item" style="font-size:10px">
            {{ this.showtext3 }}
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row :gutter="20">
      <el-col :span="16">
        <el-row>
          <div>
            <div id="myChart" style="width: 700px; height: 300px;margin-top:10px;background-color:#ffffff;">
            </div>
          </div>
        </el-row>
        <el-row>
          <div>
            <div id="Chart" style="width: 700px; height: 200px;margin-top:10px;background-color:#ffffff;">
            </div>
          </div>
        </el-row>
      </el-col>
      <el-col :span="8">
        <el-card shadow="hover">
          <el-row>
            <el-tabs v-model="activesort" @tab-click="shandleClick">
              <el-tab-pane style="width:15%" label="展现排行" name="1"></el-tab-pane>
              <el-tab-pane style="width:15%" label="点击排行" name="2"></el-tab-pane>
            </el-tabs>
          </el-row>
          <el-row>
            <el-table :data="paitableData">
              <el-table-column prop="title" label="搜索词" width="210"></el-table-column>
              <el-table-column prop="count" label="次数"></el-table-column>
            </el-table>
          </el-row>
        </el-card>
      </el-col>
    </el-row>

    <el-row style="margin-top: 20px">
      <el-row>
        <el-col :span="20">
          <span> 商品点击明细</span>
        </el-col>
        <el-col :span="4">
          <el-button-group style="float:right;">
            <el-button type="success" icon="el-icon-s-platform" size="small" @click="commodityClickspc()"></el-button>
            <el-button type="success" size="small" @click="commodityClicksph()"><i class="el-icon-mobile-phone"></i>
            </el-button>
          </el-button-group>
        </el-col>
      </el-row>
      <el-row>
        <el-table
            :data="stableData"
            style="width: 100%; margin-top: 10px"
            border>
          <el-table-column
              prop="searchWord"
              label="搜索词"
              width="900">
          </el-table-column>
          <el-table-column
              prop="hits"
              label="展现量"
              align="center">
          </el-table-column>
        </el-table>
      </el-row>
    </el-row>
    <el-row>
      <div style="margin-top:50px">
        <span> 商品展示明细</span>
        <el-table
            :data="ptableData"
            style="width: 100%; margin-top: 10px"
            border>
          <el-table-column
              prop="searchWord"
              label="搜索词"
              width="900"
          >
          </el-table-column>
          <el-table-column
              prop="hits"
              label="展现量"
              align="center"
          >
          </el-table-column>
        </el-table>

      </div>
    </el-row>
  </el-card>


</template>


<script>

export default {
  data() {
    return {
      pc: true,
      showtext1: "7日内展现次数",
      showtext2: "7日内点击次数",
      showtext3: " 总展示次数",
      paitableData: [],
      startTime: null,
      endTime: null,
      stableData: [],
      ptableData: [],
      customerOptions: [],
      customerId: null,
      activeName: 7,
      activesort: "1",
      activesorts: true,
      showCount: 0,
      clickCount: 0,
      totalShowCount: 0,
      month: null,
      myChartOption: {
        title: {text: '点击次数趋势'},
        tooltip: {},
        xAxis: {
          name: "日期",
          data: ["3-31", "4-1", "4-2", "4-3", "4-4", "4-5"]
        },
        yAxis: {
          name: "数量",
        },
        series: [{
          name: '销量',
          type: 'line',
          data: []

        }]
      },
      ChartOption: {
        title: {text: '展现次数趋势'},
        tooltip: {},
        xAxis: {
          name: "日期",
          data: ["3-31", "4-1", "4-2", "4-3", "4-4", "4-5"]
        },
        yAxis: {
          name: "数量",

        },
        series: [{
          name: '销量',
          type: 'bar',
          data: []

        }]
      },

    }
  },
  methods: {
    handleClick(day) {
      this.activeName = day
      this.showLineCharts();
    },
    shandleClick(tab, event) {
      console.log(tab, event);
      this.showLineCharts();
    },
    gettimes() {
      if (this.month != null) {
        const a = new Date(this.month)
        const resDatee = a.getFullYear() + '-' + this.p((a.getMonth() + 1)) + '-' + this.p(a.getDate())
        this.startTime = resDatee;
        let ress = '';
        if (a.getMonth() + 1 == 1 || a.getMonth() + 1 == 3 || a.getMonth() + 1 == 5 || a.getMonth() + 1 == 7 || a.getMonth() + 1 == 8 || a.getMonth() + 1 == 10 || a.getMonth() + 1 == 12) {
          ress = new Date(+new Date(resDatee) + 30 * 24 * 3600 * 1000)

        } else if (a.getMonth() + 1 == 2) {
          if (((0 == a.getFullYear() % 4) && (0 != a.getFullYear() % 100)) || (0 == a.getFullYear() % 400)) {
            ress = new Date(+new Date(resDatee) + 28 * 24 * 3600 * 1000)
          } else {
            ress = new Date(+new Date(resDatee) + 27 * 24 * 3600 * 1000)
          }

        } else {
          ress = new Date(+new Date(resDatee) + 29 * 24 * 3600 * 1000)

        }

        const b = new Date(ress)
        const eendTime = b.getFullYear() + '-' + this.p((b.getMonth() + 1)) + '-' + this.p(b.getDate())
        this.endTime = eendTime;

      }
      console.log(this.endTime, this.startTime)

    },
    p(s) {
      return s < 10 ? '0' + s : s
    },
    showLineCharts() {
      if (this.customerId == null) {
        this.$message("请先选择客户")
      } else {
        this.gettimes();
        this.timeupdate();
        this.$api.get(
            "/aicaigou/list",
            null,
            {
              custId: this.customerId,
              day: this.activeName,
              startTime: this.startTime,
              endTime: this.endTime,
              show: this.activesorts,
              pc: this.pc,
            },
            (successRes) => {
              this.stableData = successRes.data.commodityClicks;
              this.ptableData = successRes.data.commodityShows;
              this.paitableData = successRes.data.ranks;
              this.showCount = successRes.data.showCount;
              this.clickCount = successRes.data.clickCount;
              this.totalShowCount = successRes.data.totalShowCount;
              const myChartdata = successRes.data.searchClicks;
              const Chartdata = successRes.data.searchShows;
              if (myChartdata.length > 0) {
                this.myChartOption.xAxis.data = myChartdata.map((data) => data.x);
                this.myChartOption.series[0].data = myChartdata.map((data) => data.y);
                this.myChart = this.$echarts.init(document.getElementById('myChart'))
                this.myChart.setOption(this.myChartOption);
                console.log(this.myChartOption.series.data);
              }
              if (Chartdata.length > 0) {
                this.ChartOption.xAxis.data = Chartdata.map((data) => data.x);
                this.ChartOption.series[0].data = Chartdata.map((data) => data.y);
                this.Chart = this.$echarts.init(document.getElementById('Chart'))
                this.Chart.setOption(this.ChartOption);
              }
            },
            (failureRes) => {
              console.log(failureRes);
            }
        );

      }
    },
    timeupdate() {
      if (this.activesort == 1) {
        this.activesorts = true
      } else {
        this.activesorts = false
      }
      if (this.month != null) {
        console.log(this.startTIme);
        this.activeName = null,
        console.log(this.activeName);
        this.showtext1 = this.startTime + "至" + this.endTime + "内展现次数",
        this.showtext2 = this.startTime + "至" + this.endTime + "内点击次数"
      } else if (this.month == null) {
        this.startTime = null
        this.endTime = null
        if (this.activeName == 30) {
        this.showtext1 = "30日内展现次数",
        this.showtext2 = "30日内点击次数"
        } else if (this.activeName == 15) {
          this.showtext1 = "15日内展现次数",
          this.showtext2 = "15日内点击次数"
        } else {
          this.activeName = 7,
          this.showtext1 = "7日内展现次数",
          this.showtext2 = "7日内点击次数"
        }
      }
    },
    getCustomerList() {

      this.$api.get(
          "/customer/list",
          null,
          {
            list: false
          },
          (successRes) => {
            this.customerOptions = successRes.data.rows;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    commodityClicksph() {
      this.pc = false,
          this.showLineCharts()

    },
    commodityClickspc() {
      this.pc = true,
          this.showLineCharts()

    },


  },
  mounted() {
    this.getCustomerList();
    this.showLineCharts();

  },
};
</script>
<style scoped>

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.show {
  margin-top: 80px;
  position: relative;
}

.show .right {
  position: absolute;
  right: 0;
  top: 0;
}

.time {
  position: absolute;
  right: 0;
  top: 0;
  height: 36px;
}

</style>